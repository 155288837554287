import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'BSS', // e.g: 'Name | Developer'
  lang: 'pl', // e.g: en, es, fr, jp
  description: 'Brand System Soda', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  img: 'hero.jpg',
  title: '',
  name: '',
  subtitle: 'kompleksowe czyszczenie i malowanie',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  img2: 'profile2.jpeg',
  img3: 'sodablast.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

export const logoData = {
  img: 'logo.png',
};
// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: 'Renowacja zakładu Kofama Koźle S.A. w Kędzierzynie-Koźlu',
    info: 'Rewitalizacja zakładu Kofama  Koźle S.A - nasze zadanie polegało na oczyszczeniu całej elewacji metodą piaskowania, wymianie wszystkich fug, wymiana uszkodzonych cegieł oraz impregnacja całego budynku.',
    info2: 'Założeniem inwestora było przywrócenie wyglądu budynku sprzed stu lat. Chyba nam się udało',
    url: '/realizacje',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project2.jpg',
    title: 'Amfiteatr w Białej Podlaskiej',
    info: 'Czyszczenie amfiteatru w Białej Podlaskiej oraz malowanie oczyszczonej powierzchni',
    info2: '',
    url: '/realizacje',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project3.jpg',
    title: 'Czyszczenie zabudowy samochodu transportującego zwierzęta.',
    info: 'Wymiana skorodowanych elementów oraz malowanie całości.',
    info2: '',
    url: '/realizacje',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'biuro@bssoda.pl',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'facebook',
      url: 'https://www.facebook.com/BSS-103686458311013',
    },
    {
      id: uuidv1(),
      name: 'youtube',
      url: 'https://www.youtube.com/user/imejlgimejl',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
